import TopBar from "./00-page-components/00-top-bar";
import {motion} from 'framer-motion'

export default function AboutMe() {
    const fadeInInitial = {opacity: "0%", y: 20}
    const fadeInAnimate = {opacity: "100%", y: 0}
    const fadeInTransition = {duration: .85, delay: .05, type: "ea"}

    return <div className="homepage">
        <TopBar/>
        <motion.div 
          className="homepage-content"
          initial={fadeInInitial}
          whileInView={fadeInAnimate}
          transition={fadeInTransition}
          viewport={{once: true}}  
        >
            <a class="resume-download" href="https://drive.google.com/file/d/1PDyjCmcZqeD_NpeslHo7iQ0fDn7bra-C/view?usp=sharing" target="_blank">Download Resume PDF</a>
            <img src='99-sitewide/Dallin-Higgins-Resume.jpg'/>
        </motion.div>
    </div>
}