import './style.css'
import ReactDOM from 'react-dom/client'
import {motion} from 'framer-motion'
import TopBar from './00-page-components/00-top-bar'
import BottomBar from './00-page-components/01-bottom-bar'

export default function Project()
{
    const fadeInInitial = {opacity: "0%", y: 20}
    const fadeInAnimate = {opacity: "100%", y: 0}
    const fadeInTransition = {duration: .85, delay: .05, type: "ea"}

    function PhoneColumn(props) {
        return <div className="device-column" style={{position: 'relative'}}>
            <img className="delta-mobile-screen" src={props.image} style={{borderRadius: '24px', position: 'absolute', top: "15px", left: "14px"}}/>
            <img src='99-sitewide/01-phone.png' style={{position: 'relative', zIndex: '2', width: '310px'}}/>
            <div className="text-content">
                <div className="header">{props.title}</div>
                <div className="description">{props.description}</div>
            </div>
        </div>
    }

    function TabletColumn(props) {
        return <div className="device-column" style={{position: 'relative'}}>
        <img src={props.image} style={{borderRadius: '8px'}}/>
        <div className="text-content">
            <div className="header">{props.title}</div>
            <div className="description">{props.description}</div>
        </div>
    </div>
    }

    return <>
        <TopBar/>
        <div className="delta write-up-wrapper">
            <motion.div 
              className="project-header"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="project-title">Delta Airlines</div>
                <div className="project-subtitle">Enterprise Dashboard Software</div>
                <div className="project-image">
                    <img src='03-delta/00-Hero-Mockup.jpg'/>
                </div>
            </motion.div>

            <motion.div 
              className="project-summary written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Problem. Process. Product.</div>
                <div className="three-columns">
                    <div className="column">
                        <div className="header">
                            <div className="main-header">Why?</div>
                            <div className="subheader">Problem Statement.</div>
                        </div>
                        <div className="description">Delta’s team managers use many outdated and unintuitive programs, some as old as 1995</div>
                    </div>
                    <div className="column">
                        <div className="header">
                            <div className="main-header">How?</div>
                            <div className="subheader">Design Process.</div>
                        </div>
                        <div className="description">This 10 week engagement followed a traditional UX process of interviews, personas, journey maps, and high fidelity UI</div>
                    </div>
                    <div className="column">
                        <div className="header">
                            <div className="main-header">What?</div>
                            <div className="subheader">End Product.</div>
                        </div>
                        <div className="description">End users were so happy with the final work, one even cried in user testing. This project is currently in development.</div>
                    </div>
                </div>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Interviews, Personas & Journey Maps</div>
                <p>Our first goal was gaining an understanding of the needs, frustrations, goals, and day in the life of Delta managers from the three different divisions we were targeting with our application by conducting interviews with managers from each of division. We used that information to build our personas and journey maps to understand our target users.</p>
                <div className="three-column-cards">
                    <div className="card">
                        <div className="header">Airport Customer Service (ACS)</div>
                        <div className="description">Often called “red coats” this team are the gate agents and primary customer-facing employee working in airports</div>
                    </div>
                    <div className="card">
                        <div className="header">Technical Operations (TechOps)</div>
                        <div className="description">TechOps are the engineers of Delta, working on plane repairs and maintenance in 24/7 shifts</div>
                    </div>
                    <div className="card">
                        <div className="header">Reservations</div>
                        <div className="description">The reservations team work in call centers, answering customer questions and moving and creating reservations</div>
                    </div>
                </div>
                <img src='03-delta/01-Persona.jpg'/>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">
                    Day in the Life.
                    <div className="subheader">(you're going to need to scroll...)</div>
                </div>
                <div className="user-journey">
                    <img src='03-delta/02-UserJourneyMap.jpg'/>
                </div>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Design Thinking Workshops</div>
                <p>We set up co-creation workshops with each of the 3 user groups in order to validate our assumptions, capture new pain points and frustrations, and generate ideas with our users. I used IBM’s Enterprise Design Thinking framework as a baseline for conducting this session with users, building on and tweaking it to fit what we needed to learn for this project. By bringing users into our design process at this stage, it built an elevated level of trust between us and the users that we worked with throughout the process, which was an invaluable relationship as we designed.</p>
                <img src='03-delta/03-EDT.jpg'/>
            </motion.div>

            <div className="written-section">
                <motion.div 
                  className="title" 
                  style={{marginBottom: '24px'}}
                  initial={fadeInInitial}
                  whileInView={fadeInAnimate}
                  transition={fadeInTransition}
                  viewport={{once: true}}
                >
                    Final Product
                </motion.div>
                <motion.div 
                  className="subsection"
                  initial={fadeInInitial}
                  whileInView={fadeInAnimate}
                  transition={fadeInTransition}
                  viewport={{once: true}}
                >
                    <div className="subtitle">Airport Customer Service</div>
                    <p>ACS managers are constantly balancing customer requests and their own responsibilities to the team, meaning they’re always on the move and need a mobile-first solution that allows them to manage how their team is performing with metrics like Net Promoter Score, as well as the operations of the airport, such as delays and diversions.</p>
                    <div className="three-column-phone">
                        <PhoneColumn
                            image='03-delta/04-Mobile1.jpg'
                            title="Homepage"    
                            description="In the new experience, managers can see the content that matters most to them: team metrics, operation details, team insights, and safety bulletins."
                        />
                        <PhoneColumn
                            image='03-delta/04-Mobile2.jpg'
                            title="My Team"    
                            description="Managers frequently told us current tools felt impersonal and robotic, so in addition to a streamlined team view, we added anniversary and birthday icons to better facilitate the human part of management."
                        />
                        <PhoneColumn
                            image='03-delta/04-Mobile3.jpg'
                            title="Weather"    
                            description="ACS needs to know about upcoming weather pattern predictions and irregular operation stations, to better serve customers who need help."
                        />
                    </div>
                </motion.div>
                <motion.div 
                  className="subsection"
                  initial={fadeInInitial}
                  whileInView={fadeInAnimate}
                  transition={fadeInTransition}
                  viewport={{once: true}}
                >
                    <div className="subtitle">Technical Operations (TechOps)</div>
                    <p>Technical Operations managers have a function-oriented role. Every time something as small as a single bolt is removed from a plane, that is logged into Delta’s system. Managers have to keep track of low inventory for parts & issues with repair while balancing the human part of managing their direct reports personally. They use a tablet form factor as they move between employees, documenting things for the next shift manager.</p>
                    <div className="two-column-tablet">
                        <TabletColumn
                            image='03-delta/05-Tablet1.jpg'
                            title="Homepage"    
                            description="In the new experience, managers can see the content that matters most to them: team metrics, operation details, team insights, and safety bulletins."
                        />
                        <TabletColumn
                            image='03-delta/05-Tablet2.jpg'
                            title="My Team"    
                            description="Managers frequently told us current tools felt impersonal and robotic, so in addition to a streamlined team view, we added anniversary and birthday icons to better facilitate the human part of management."
                        />
                    </div>
                </motion.div>
                <motion.div 
                  className="subsection" 
                  style={{marginBottom: '24px'}}
                  initial={fadeInInitial}
                  whileInView={fadeInAnimate}
                  transition={fadeInTransition}
                  viewport={{once: true}}
                  >
                    <div className="subtitle">Reservations</div>
                    <p>The reservations team work on large, widescreen monitors, to change and create customer reservations while on the phone. For this, we targeted widescreen desktop first, and used the extra screen real estate to solve some of the reservations specific problems we identified: high quantities of communications & emails, emphasis on promoter scores, and analytics around meeting and training attendance.</p>
                    <div className="device-column">
                        <img src='03-delta/06-Desktop1.jpg'/>
                        <div className="header">Employee Profile</div>
                        <div className="description">Reservations managers are generally extroverted and motivated by their relationship with their employees. We put features like the employee profile first to make sure they can stay up to date with learning management and important milestones.</div>
                    </div>
                </motion.div>
            </div>

            <motion.div 
              className="written-section conclusion"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Outcome: Was it successful?</div>
                <p>The Figma prototypes tested extremely well with the target demographics. Leadership loved the concepts and were passionate about pushing the project through to completion to empower their frontline leaders. Unfortunately, an internal team was hired to complete the project so I rolled off the project after a several week design hand-off for it to be completed internally at Delta.</p>
            </motion.div>
        </div>
        <BottomBar
            nextProject='Lenovo'
            nextProjectUrl='/lenovo'
        />
    </>
}