import './style.css'
import ReactDOM from 'react-dom/client'
import {motion} from 'framer-motion'
import TopBar from './00-page-components/00-top-bar'
import BottomBar from './00-page-components/01-bottom-bar'

export default function Project()
{
    const fadeInInitial = {opacity: "0%", y: 20}
    const fadeInAnimate = {opacity: "100%", y: 0}
    const fadeInTransition = {duration: .85, delay: .05, type: "ea"}

    function CompCard(props) {
        
        return <div className="card" style={{marginTop: props.yPosition}}>
            <div className="header">{props.header}</div>
            <div className="description">{props.description}</div>
        </div>
    }

    return <>
        <TopBar/>
        <div className="write-up-wrapper">
            <motion.div 
              className="project-header"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="project-title">Lenovo</div>
                <div className="project-subtitle">Consumer Website Exploration</div>
                <div className="project-image">
                    <img src='01-lenovo/00-Hero-Mockup.jpg'/>
                </div>
            </motion.div>

            <motion.div 
              className="project-summary written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Problem. Process. Product.</div>
                <div className="three-columns">
                    <div className="column">
                        <div className="header">
                            <div className="main-header">Why?</div>
                            <div className="subheader">Problem Statement.</div>
                        </div>
                        <div className="description">Lenovo wants a more “modern” web experience that is more aimed toward their B2B clients</div>
                    </div>
                    <div className="column">
                        <div className="header">
                            <div className="main-header">How?</div>
                            <div className="subheader">Design Process.</div>
                        </div>
                        <div className="description">Over 2 weeks we conducted user interviews, created designs, iterated, and presented back to the client</div>
                    </div>
                    <div className="column">
                        <div className="header">
                            <div className="main-header">What?</div>
                            <div className="subheader">End Product.</div>
                        </div>
                        <div className="description">We won the bid for a design phase by presenting back two potential design directions for the website</div>
                    </div>
                </div>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Personas & 4 E-Commerce Design Principles</div>
                <p>We interviewed 8 individuals who work in software procurement, and 4 individuals who have shopped for their own phone or computer recently, in order to compare and contrast the goals and frustrations of these two target audiences. From this research, we formed our 4 experience principles that guided out design process, and created our personas for B2B and B2C customers, making sure to balance both their needs in our final product presentation.</p>
                <img src='01-lenovo/01-Personas.jpg'/>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">
                    Final Product
                    <div className="subheader">(or so I thought...)</div>
                </div>
                <p>3 days until proposal time. Here are some of the screens I was planning to present to the client. Solid UX recommendations and a safe redesign. When designing for e-commerce, and considering an older average user age, I typically aim for best practices and familiar interactions, rather than trying to provide something too jarringly new or exciting for customers.</p>
                <div className="desktop-comps-section">
                    <div className="left-rail">
                        <img src='01-lenovo/02-Homepage1.jpg'/>
                    </div>
                    <div className="right-rail">
                        <div className="title">Homepage</div>
                        <CompCard
                            header="Reimagined Header"
                            description="I took note of every page available from Lenovo’s current global navigation and then re-sorted it by more intuitive categories"
                            yPosition={11}
                        />
                        <CompCard
                            header="Lifestyle Imagery"
                            description="Having a big picture of a laptop might not be the most exciting landing page for a user, so I created 6 headers that connect to the life Lenovo’s products enable."
                            yPosition={42}
                        />
                        <CompCard
                            header="Improved Copywriting"
                            description="After browsing Lenovo’s B2B offerings, I had a good understanding of the language so that I could create new content around their services."
                            yPosition={61}
                        />
                        <CompCard
                            header="12 Column Grid"
                            description="Introducing a flexible grid allows for 1, 2, 3, 4, and 6 columns to fit within the space and provide dynamic content."
                            yPosition={147}
                        />
                    </div>
                </div>
                <div className="desktop-comps-section">
                    <div className="left-rail">
                        <img src='01-lenovo/03-PLP.jpg'/>
                    </div>
                    <div className="right-rail">
                        <div className="title">Product Page</div>
                        <CompCard
                            header="Streamlined Product Info"
                            description="The main issue with their previous product page is poor hierarchy resulting in poor scannability and lack of clear next steps"
                            yPosition={11}
                        />
                        <CompCard
                            header="Condensed Text Styles"
                            description="A common issue on this type of page is using 6 or 7 text styles to communicate information, which pulls the user’s attention in many different directions"
                            yPosition={42}
                        />
                        <CompCard
                            header="Illustrated Features"
                            description="Pulling out a few key features of the device and connecting them to the tangible form factor helps "
                            yPosition={61}
                        />
                    </div>
                </div>
            </motion.div>

            <motion.div 
              className="disruptor"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">"We wanted something a bit more...</div>
                <div className="inverse" style={{marginLeft: "8px"}}>modern</div>
                <div className="title">"</div>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">
                    Final Product
                    <div className="subheader">(For real this time!)</div>
                </div>
                <p>Here is what we ended up presenting to Lenovo. I used Blender for the 3D modeling, materials, and rendering. Then I took those renders and built UI around them based on the content I’ve read throughout the website, after reading through the language they use to engage their B2B customers.</p>
                <div className="desktop-comps-section">
                    <div className="left-rail">
                        <img src='01-lenovo/04-Homepage2.jpg'/>
                    </div>
                    <div className="right-rail">
                        <div className="title">Homepage</div>
                        <CompCard
                            header="Minimized Global Navigation"
                            description="To go along with the new visual direction, I tried to reduce the homepage to get a more minimalist overall look and feel."
                            yPosition={11}
                        />
                        <CompCard
                            header="3D Illustrations"
                            description="I used an isometric art style to make it feel more “modern”, engaging, and approachable for B2B customers."
                            yPosition={60}
                        />
                        <CompCard
                            header="Improved Copywriting"
                            description="While writing homepage content, a big challenge was distilling something complex into simple terminology."
                            yPosition={160}
                        />
                        <CompCard
                            header="Intentional CTAs"
                            description="Each section is designed to capture a different audience: B2B and B2C customers that know what they want, or are not sure yet."
                            yPosition={394}
                        />
                        <CompCard
                            header="Increasing Conversion"
                            description="Currently, their B2B model is based heavily around getting customers on the phone to discuss options. It makes sense to allow them to access that flow from the homepage."
                            yPosition={317}
                        />
                    </div>
                </div>
                <div className="desktop-comps-section">
                    <div className="left-rail">
                        <img src='01-lenovo/05-OneDT1.jpg'/>
                        <img src='01-lenovo/05-OneDT2.jpg'/>
                        <img src='01-lenovo/05-OneDT3.jpg'/>
                    </div>
                    <div className="right-rail">
                        <div className="title">One Lenovo</div>
                        <div className="description">This is our signature feature: The One Lenovo Experience quiz. A six question quiz that results in recommended products that fit your needs.</div>
                        <CompCard
                            header="3D Art"
                            description="I wanted to continue the theme of 3D shapes across the website, but make this experience feel unique and immersive by changing the camera angle and lighting"
                            yPosition={32}
                        />
                        <CompCard
                            header="Asking the Right Questions"
                            description="While interviewing B2B software procurers, I asked the kinds of data points and criteria go into making their purchase decisions, and used those to guide the 6 variable questions in this experience."
                            yPosition={277}
                        />
                        <CompCard
                            header="Simple Questions & Easy Decisions"
                            description="I’m a big believer in “3 easy choices is equal to 1 difficult one” so I tried to make the decision branches as simple as possible, while using 3D visuals to represent the crossroads of the question."
                            yPosition={71}
                        />
                        <CompCard
                            header="Transparency in Recommendationss"
                            description="A big mistake e-commerce quizzes often make is not explaining why the suggested products are suggested. A “Why it’s a great match” section gives the user transparency and confidence"
                            yPosition={249}
                        />
                        <CompCard
                            header="Always Be (Up)Selling"
                            description="I wanted to show the value of extra offerings by clearly calling them out as add-ons that can be discussed in the consultation call."
                            yPosition={71}
                        />
                    </div>
                </div>
            </motion.div>

            <motion.div 
              className="written-section conclusion"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Outcome: Was it successful?</div>
                <p>All of this work (and more!) was accomplished within 2 weeks to respond to an RFP. As a result of the proposal, Lenovo agreed to pay for a discovery & design phase with IBM. Unfortunately, I couldn’t be a part of that as I moved onto other projects. You can read about those below too.</p>
            </motion.div>
        </div>
        <BottomBar
            nextProject='AEP'
            nextProjectUrl='/aep'
        />
    </>
}