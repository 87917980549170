import './style.css'
import ReactDOM from 'react-dom/client'
import {motion, useAnimationControls} from 'framer-motion'
import { useState } from 'react'
import TopBar from './00-page-components/00-top-bar.jsx'
import DiscountTire from './discounttire.jsx'
import { Link } from 'react-router-dom'

export default function Homepage()
{
    const fadeInInitial = {opacity: "0%", y: 20}
    const fadeInAnimate = {opacity: "100%", y: 0}
    const fadeInTransition = {duration: .85, delay: .05, type: "ea"}
    const controls = useAnimationControls()

    const [id, setID] = useState([0,0])
    const [link, setLink] = useState('/')

    const imageSrcs = [
        '00-homepage/02-screens/01-discounttire.png',
        '00-homepage/02-screens/02-delta.png',
        '00-homepage/02-screens/03-lenovo.png',
        '00-homepage/02-screens/04-aep.png'
    ]

    const gradients = [
        'linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(38,38,38,1) 100%)',
        'linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(194,35,35,1) 100%)',
        'linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(38,35,194,1) 100%)',
        'linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(100,164,240,1) 100%)',
        'linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(180,125,2,1) 100%)'
    ]

    const titles = [
        'Dallin Higgins',
        'Discount Tire',
        'Delta Airlines',
        'Lenovo',
        'AEP'
    ]

    const subtitles = [
        'I’m a product designer at IBM with a love for 3D art and React development',
        'An e-commerce web redesign that led to $51 million increase in annual revenue',
        'An employee dashboard to improve the efficiency of frontline managers',
        'A conceptual redesign of Lenovo’s website to make it more B2B oriented',
        'Prototyping VR training for employees using Gen AI and Figma'
    ]

    const links = [
      '/',
      '/discounttire',
      '/delta',
      '/lenovo',
      '/aep'
    ]

    const spaceIndex = [
      6,
      8,
      5,
      -1,
      -1
    ]

    function ProjectsSection(props) {
        return <div className="projects-section">
            <div className="title">Client Projects</div>
            <Link to="/discounttire">
                <ProjectButton
                id={1} 
                name='Discount Tire'
                description='Consumer Website Redesign'
                />
            </Link>
            <Link to="/delta">
                <ProjectButton 
                id={2} 
                name='Delta Airlines'
                description='Enterprise Dashboard Software'
                />
            </Link>
            <Link to="/lenovo">
                <ProjectButton 
                id={3} 
                name='Lenovo'
                description='Website Redesign Experimentation'
                />
            </Link>
            <Link to="/aep">
                <ProjectButton 
                id={4} 
                name='AEP'
                description='VR Training Prototype'
                />
            </Link>
        </div>
    }

    function ProjectButton(props) {
        const [hover, isHover] = useState(false)
        
        function onHover() {
            // push to an array: previous, current
            if(id[1] != props.id)
              setID([id[1],props.id])
            isHover(true)
            controls.start("hovered")
            setLink(links[id[1]])
        }

        function offHover() {
          if(id[1] != 0)
            setID([id[1],0])
          isHover(false)
          controls.start("hovered")
        }

        return <motion.div className="project-button"
                  onMouseEnter={()=> onHover()}
                  style={hover ? {backgroundColor: 'rgba(255,255,255,.1)'} :{backgroundColor: 'rgba(255,255,255,0)'}}
                >
            <div className="name">{props.name}</div>
            <div className="description">{props.description}</div>
        </motion.div>
    }

    function Title({children}) {
        return (
            <motion.div 
              className="title-container"
              initial="initial"
            >
                <div className="title">
                    {titles[id[0]].split("").map((l, i) => {
                        return <motion.span
                          variants={{
                            initial: {
                              y: "0%"
                            },
                            hovered: {
                                y: "-100%"
                            },
                          }}
                          transition={{
                            delay: .02*i
                          }}
                          animate={controls}
                          key={i}
                          style={spaceIndex[id[0]] == i? {marginRight:'24px'}:{marginRight:'0px'}}>
                            {l}
                        </motion.span>
                })}</div>
                <div className="title">
                    {titles[id[1]].split("").map((l, i) => {
                        return <motion.span
                          variants={{
                            initial: {
                              y: "0%"
                            },
                            hovered: {
                                y: "-100%"
                            },
                          }}
                          transition={{
                            delay: .02*i
                          }}
                          animate={controls}
                          key={i}
                          style={spaceIndex[id[1]] == i? {marginRight:'24px'}:{marginRight:'0px'}}>
                            {l}
                        </motion.span>
                })}</div>
            </motion.div>
        )
    }

    return <div className="homepage" style={{backgroundImage: gradients[id[1]], transition: 'backgroundImage 2s'}}>
        <TopBar/>
        <motion.div 
          className="homepage-content"
          initial={fadeInInitial}
          whileInView={fadeInAnimate}
          transition={fadeInTransition}
          viewport={{once: true}}
        >
            <div className="homepage-header">
                <p style={id[1] == 0 ? {opacity: '1'} : {opacity: '0'}}>Hi, my name is</p>
                <Title>{titles[id]}</Title>
                <div className="subtitle">{subtitles[id[1]]}</div>
                {/* I’m a <strong>product designer</strong> at <strong>IBM</strong> with a love for <strong>3D art</strong> and <strong>React development</strong> */}
            </div>
            <ProjectsSection/>
            <Link to={link}>
              <div className="phone-screen" >
                  <motion.img 
                    src={imageSrcs[id[1]-1]}
                    variants={{
                      initial: {y: 20},
                      hovered: {y: -20}
                    }}
                    whileHover="hovered"
                  // animate={controls}
                  />
              </div>
            </Link>
        </motion.div>
    </div>
}