import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
// import Experience from './Experience.jsx'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'

import Homepage from './homepage.jsx'
import Lenovo from './lenovo.jsx'
import Delta from './delta.jsx'
import DiscountTire from './discounttire.jsx'
import AEP from './aep.jsx'
import TopBar from './00-page-components/00-top-bar.jsx'
import Resume from './resume.jsx'
import About from './aboutme.jsx'
import { element } from 'three/examples/jsm/nodes/Nodes.js'

const root = ReactDOM.createRoot(document.querySelector('#root'))

const router = createBrowserRouter([
    {
        path: '/',
        element: <Homepage />
    },
    {
        path: '/discounttire',
        element: <DiscountTire />
    },
    {
        path: '/delta',
        element: <Delta />
    },
    {
        path: '/lenovo',
        element: <Lenovo />
    },
    {
        path: '/aep',
        element: <AEP />
    },
    {
        path: '/resume',
        element: <Resume />
    },
    {
        path: '/aboutme',
        element: <About />
    }
])

root.render(
    <>
    {/* <TopBar/> */}
    {/* <Homepage/> */}
    {/* <DiscountTire/> */}
    {/* <Lenovo/> */}
    {/* <Delta/> */}
    {/* <AEP/> */}
    <RouterProvider router={router} />
    </>
    // <Canvas
    //     camera={ {
    //         fov: 45,
    //         near: 0.1,
    //         far: 200,
    //         position: [ 0,2,8 ]
    //     } }
    // >
    //     <Experience />
    // </Canvas>
)