import '../style.css'
import ReactDOM from 'react-dom/client'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom'

export default function TopBar()
{
    const fadeInInitial = {opacity: "0%", y: 20}
    const fadeInAnimate = {opacity: "100%", y: 0}
    const fadeInTransition = {duration: .85, delay: .05, type: "ea"}

    return <div className="top-bar">
        <div className="container">
            <div className="left-side"><a href="/">Dallin Higgins</a></div>
            <div className="right-side">
                {/* <Link to='/resume'><div className="item">Resume</div></Link> */}
                <Link to='/aboutme'><div className="item">About Me</div></Link>
            </div>
        </div>
    </div>
    
}