import TopBar from "./00-page-components/00-top-bar";
import {motion} from 'framer-motion';
import { useState } from "react";
import { Link } from 'react-router-dom';

export default function AboutMe() {
    const fadeInInitial = {opacity: "0%", y: 20}
    const fadeInAnimate = {opacity: "100%", y: 0}
    const fadeInTransition = {duration: .85, delay: .05, type: "ea"}

    function ProgrammingProject(props) {
        const [hover, isHover] = useState(false)

        function onHover() {
            isHover(true)
        }

        return <a href={props.url} target="_blank">
            <div className="project" 
            onMouseEnter={()=> onHover()}
            onMouseLeave={()=> isHover(false)}
            >
                <img src={props.image} style={hover ? {opacity: '40%'} : {opacity: '100%'}}/>
                {hover && <motion.div 
                className="text"
                >
                    <div className="title">{props.title}</div>
                    <div className="duration">{props.duration}</div>
                </motion.div> }
            </div>
        </a>
    }

    return <div className="aboutpage">
        <TopBar/>
        <motion.div 
          className="homepage-content"
          initial={fadeInInitial}
          whileInView={fadeInAnimate}
          transition={fadeInTransition}
          viewport={{once: true}}
        >
            <div className="aboutme">
                <div className="title-bar">
                    <div className="title"> Hi, I'm Dallin </div>
                    <div className="links">
                        <a href="https://drive.google.com/file/d/1KQ9u4Kdk-NKJhl1qKZf5gf0EnHqh326A/view?usp=sharing" target="_blank">My Resume</a>
                        <a href="https://www.linkedin.com/in/dallinhiggins/" target="_blank">My LinkedIn</a>
                    </div>
                </div>
                <p>I’m a product designer and creative developer based in Atlanta, GA, currently working for IBM iX bringing digital experiences to life for clients. I love making cool stuff and showing it off, and I’m extremely fortunate to be able to make a living doing that. </p>
                <p>Outside of work, I’m passionate with the fusion of 2D & 3D for web experiences by using Blender, Figma, React, Three.JS, and React-Three-Fiber. Here are some examples of quick prototypes and examples I’ve created with those, all 100% designed, modeled, and developed by myself, but please reach out if you’d like to see more. </p>
                

                <div className="programming-section-header">Three.JS projects</div>
                <div className="programming-projects">
                    <ProgrammingProject
                        key='0' 
                        image='05-about/00-twominutetown.jpg'
                        title='Two Minute Town'
                        duration='23 days'
                        url='https://twominutetown.web.app/'
                    />
                    <ProgrammingProject
                        key='1' 
                        image='05-about/01-spatialcomputing.jpg'
                        title='Spatial Computing Lab Floorplan'
                        duration='11 days'
                        url='https://atlantaxrhub.web.app/'
                    />
                    <ProgrammingProject
                        key='2' 
                        image='05-about/02-economic.jpg'
                        title='Economic Adventure'
                        duration='3 days'
                        url='https://economicadventure.web.app//'
                    />
                    <ProgrammingProject
                        key='3' 
                        image='05-about/03-designassembly.jpg'
                        title='Design Assembly'
                        duration='4 hours'
                        url='https://designassemblyline.web.app/'
                    />
                </div>
            </div>
        </motion.div>
    </div>
}