import './style.css'
import ReactDOM from 'react-dom/client'
import {motion} from 'framer-motion'
import TopBar from './00-page-components/00-top-bar'
import BottomBar from './00-page-components/01-bottom-bar'

export default function Project()
{
    const fadeInInitial = {opacity: "0%", y: 20}
    const fadeInAnimate = {opacity: "100%", y: 0}
    const fadeInTransition = {duration: .85, delay: .05, type: "ea"}

    return <>
        <TopBar/>
        <div className="write-up-wrapper">
            <motion.div 
              className="project-header"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="project-title">AEP</div>
                <div className="project-subtitle">Employee VR Training Prototype</div>
                <div className="project-image">
                    <img src='04-aep/00-Hero-Image.jpg'/>
                </div>
            </motion.div>

            <motion.div 
              className="project-summary written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Problem. Process. Product.</div>
                <div className="three-columns">
                    <div className="column">
                        <div className="header">
                            <div className="main-header">Why?</div>
                            <div className="subheader">Problem Statement.</div>
                        </div>
                        <div className="description">AEP wanted a presentation of what IBM could deliver for VR training for electrician employees.</div>
                    </div>
                    <div className="column">
                        <div className="header">
                            <div className="main-header">How?</div>
                            <div className="subheader">Design Process.</div>
                        </div>
                        <div className="description">Over 3 weeks, we interviewed AEP employees and used Dall-E’s gen AI along with Figma’s prototyping</div>
                    </div>
                    <div className="column">
                        <div className="header">
                            <div className="main-header">What?</div>
                            <div className="subheader">End Product.</div>
                        </div>
                        <div className="description">We delivered a Figma prototyped preview of a VR employee training experience.</div>
                    </div>
                </div>
            </motion.div>

            <motion.div 
              className="written-section conclusion"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Final Product</div>
                <p>This is the presentation we presented to AEP. It contains the presentation content created by my team as well as the experience I created through Dall-E and Figma prototyping. Feel free to click around. You may want to use full-screen in the top right.</p>
                {/* <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fdesign%2FAAKYEaXyeqFBoBl1M5Ohtp%2FAEP-Fall-Protection%3Fnode-id%3D465-508%26t%3D8zUe27Ce8aRV8LYy-1" allowfullscreen></iframe> */}
                <iframe className="aep-prototype" style={{border: "1px solid rgba(0, 0, 0, 0.1)"}} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fjep8COCikDNjgijMKpImu5%2F3D-Portfolio%3Fpage-id%3D1344%253A3152%26node-id%3D1344-3162%26viewport%3D557%252C449%252C0.03%26t%3DAkMQHq6ZN4kF2aLV-1%26scaling%3Dscale-down-width%26content-scaling%3Dfixed%26starting-point-node-id%3D1344%253A3162" allowFullScreen></iframe>
            </motion.div>
        </div>
        <BottomBar
            nextProject='Discount Tire'
            nextProjectUrl='/discounttire'
        />
    </>
}