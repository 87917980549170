import '../style.css'
import ReactDOM from 'react-dom/client'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom'

export default function BottomBar(props)
{
    const fadeInInitial = {opacity: "0%", y: 20}
    const fadeInAnimate = {opacity: "100%", y: 0}
    const fadeInTransition = {duration: .85, delay: .05, type: "ea"}

    return <div className="bottom-bar">
        <div className="content">
            <div className="title">There's a lot more to talk about with this project</div>
            <div className="reach-out description"><div>Feel free to </div><a href='https://www.linkedin.com/in/dallinhiggins/' target="_blank">reach out on LinkedIn</a><div> if you have questions</div></div>
            <div className="next-project description">
                <div>Or go to the next project:</div> 
                <Link to={props.nextProjectUrl} className='next-project-link' onClick={() => window.scrollTo(0, 0)}>
                    {props.nextProject}
                </Link>
            </div>
        </div>
    </div>
    
}