import './style.css'
import ReactDOM from 'react-dom/client'
import {motion} from 'framer-motion'
import TopBar from './00-page-components/00-top-bar'
import BottomBar from './00-page-components/01-bottom-bar'
import useMediaQuery from './useMediaQuery'

const fadeInInitial = {opacity: "0%", y: 20}
const fadeInAnimate = {opacity: "100%", y: 0}
const fadeInTransition = {duration: .85, delay: .05, type: "ea"}

const red = '#FF4141'
const green = '#25EB21'

function PhoneColumn(props) {
    
    return <div className="device-column" style={{position: 'relative', height: '1420px'}}>
        <div className="subheading" style={props.state=='Redesign' ? {color: green} : {color: red}}>{props.state}</div>
        <div className="device" style={{position: 'relative'}}>
            <img className="dt-mobile-screen" src={props.image} style={{borderRadius: '24px 24px 8px 8px', position: 'absolute', top: "15px", left: "14px"}}/>
            <img src='99-sitewide/01-phone.png' style={{position: 'relative', zIndex: '2', width: "329px"}}/>
        </div>
    </div>
}

function CompCard(props) {
    const isDesktop = useMediaQuery();
    return <div className="card" style={ isDesktop ? {marginTop: props.yPosition} : {marginTop: '24px'}}>
        <div className="header" style={props.color == 'red' ? {color: red} : {color: green}}>{props.header}</div>
        <div className="description">{props.description}</div>
    </div>
}

export default function Project()
{
    
    return <>
        <TopBar/>
        <div className="write-up-wrapper">
            <motion.div 
              className="project-header"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="project-title">Discount Tire</div>
                <div className="project-subtitle">Consumer Website Redesign</div>
                <div className="project-image">
                    <img src='02-discounttire/00-Hero-Mockup.jpg'/>
                </div>
            </motion.div>

            <motion.div 
              className="project-summary written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Problem. Process. Product.</div>
                <div className="three-columns">
                    <div className="column">
                        <div className="header">
                            <div className="main-header">Why?</div>
                            <div className="subheader">Problem Statement.</div>
                        </div>
                        <div className="description">Discount Tire wanted a website refresh of their e-commerce experience, without redo-ing the whole shopping flow.</div>
                    </div>
                    <div className="column">
                        <div className="header">
                            <div className="main-header">How?</div>
                            <div className="subheader">Design Process.</div>
                        </div>
                        <div className="description">We audited the current web experience to understand project scope and UX pain points, then created a design system and new shopping experience.</div>
                    </div>
                    <div className="column">
                        <div className="header">
                            <div className="main-header">What?</div>
                            <div className="subheader">End Product.</div>
                        </div>
                        <div className="description">The new web experience is yielding an <strong>estimated $51 million annual revenue increase</strong>, and the design system allows the experience to continue to scale and improve.</div>
                    </div>
                </div>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Design System</div>
                <p>While starting on this project, Discount Tire as an organization was migrating from Sketch to Figma. While we audited the site in a page-by-page approach, it made more sense from both a design and development perspective to start creating a design system from Discount Tire’s brand guidelines, taking inspiration from leading design systems like Carbon, Material, and Fluent.</p>
                <img src='02-discounttire/01-DesignSystem.jpg'/>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">
                    <div style={{color: red}}>Existing:</div>
                    Shopping Cart
                </div>
                <div className="desktop-comps-section">
                    <div className="left-rail">
                        <img src='02-discounttire/02-ShoppingCart1.jpg'/>
                    </div>
                    <div className="right-rail">
                        <CompCard
                            header="Floating Alignment"
                            description="There aren’t clearly defined columns in this design, so information gets cluttered and lost easily"
                            yPosition={71}
                            color='red'
                        />
                        <CompCard
                            header="Number Overload"
                            description="With so many quantities and prices, customers weren’t sure which ones were important and how they were adding up"
                            yPosition={188}
                            color='red'
                        />
                        <CompCard
                            header="Multiple Subtotals"
                            description="With several subtotal lines throughout the cart page, customers tried using calculators to understand their true total"
                            yPosition={32}
                            color='red'
                        />
                        <CompCard
                            header="Add-On Ambiguity"
                            description="Certificates, which serve as a warranty on your tire, are not clearly called out and poorly communicated to the user"
                            yPosition={84}
                            color='red'
                        />
                        <CompCard
                            header="Poor Visual Hierarchy"
                            description="A major goal of the business is to increase accounts created, but the savings incentive they offer gets lost in the overall page"
                            yPosition={30}
                            color='red'
                        />
                        <CompCard
                            header="Empty Space & Scrolling"
                            description="Lots of unintentional white space and poor component placement makes the user scroll a long way, especially on mobile"
                            yPosition={262}
                            color='red'
                        />
                    </div>
                </div>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">
                    <div style={{color: green}}>Redesigned:</div>
                    Shopping Cart
                </div>
                <div className="desktop-comps-section">
                    <div className="left-rail">
                        <img src='02-discounttire/02-ShoppingCart2.jpg'/>
                    </div>
                    <div className="right-rail">
                        <CompCard
                            header="Easier Visual Scanning"
                            description="My primary goal was to make the cart easier to scan. To do this, I used a 12 column grid to divide the page into a cart summary and order summary"
                            yPosition={44}
                            color='green'
                        />
                        <CompCard
                            header="Progressive Disclosure"
                            description="Because of the amount of copy and information that needed to be retained, I used dropdown arrows and ‘view details’ links to compress info"
                            yPosition={44}
                            color='green'
                        />
                        <CompCard
                            header="Certificates Explained"
                            description="I created a unique treatment for upsell and add-on components on products giving increased visibility to warranties and add-ons"
                            yPosition={16}
                            color='green'
                        />
                        <CompCard
                            header="Component First Approach"
                            description="Features like this floating “other ways to pay” box could be re-used on other pages like checkout, so I built components with the whole site in mind"
                            yPosition={24}
                            color='green'
                        />
                    </div>
                </div>
            </motion.div>

            <motion.div 
              className="written-section"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">
                    Product Listing Page
                </div>
                <div className="mobile-comps-section">
                    <div className="left-rail">
                        <PhoneColumn
                            image='02-discounttire/03-PLP1.jpg'
                            state='Existing'
                        />
                        <PhoneColumn
                            image='02-discounttire/03-PLP2.jpg'
                            state='Redesign'
                        />
                    </div>
                    <div className="right-rail">
                        <CompCard
                            header="Two Flows, One Page"
                            description="This tab design makes it easier to toggle between tires recommended by Discount Tire’s recommendation engine and viewing all products."
                            yPosition={140}
                            color='green'
                        />
                        <CompCard
                            header="More Customization, Easier"
                            description="In the existing design, users had to back out of the page to change their Treadwell information for updated recommendations."
                            yPosition={86}
                            color='green'
                        />
                        <CompCard
                            header="Putting Treadwell First"
                            description="Because they have business goals around increasing understanding and conversion of Treadwell it makes sense for it to be closer to the product image."
                            yPosition={60}
                            color='green'
                        />
                        <CompCard
                            header="Reorganized Product Information"
                            description="One of the main undertakings of this process was taking the same information but grouping it more intentionally."
                            yPosition={80}
                            color='green'
                        />
                        <CompCard
                            header="Visualizing Product Info"
                            description="Grouping the stopping distance and mile warranty together allows Discount Tire’s Treadwell tire guide to become its own independent feature on the PLP"
                            yPosition={264}
                            color='green'
                        />
                    </div>
                </div>
            </motion.div>

            

            <motion.div 
              className="written-section conclusion"
              initial={fadeInInitial}
              whileInView={fadeInAnimate}
              transition={fadeInTransition}
              viewport={{once: true}}>
                <div className="title">Outcome: Was it successful?</div>
                <p>Though the design and development of this project are being rolled out in several phases over the course of 18 months, within the first 10 months we experienced an estimated revenue increase of over $51 million per year, based on extrapolated A/B testing numbers.</p>
                <p>A key theme of this project was being closely involved with the development team. There was less of clear divide between design and development. Design and development worked in tandem closely, and my skills in web development really helped there be a seamless hand off and accelerate project timelines, as well as build a close working relationship with that team.</p>
            </motion.div>
        </div>
        <BottomBar
            nextProject='Delta Airlines'
            nextProjectUrl='/delta'
        />
    </>
}